import { Observable } from 'rxjs';
import { ResponseModel } from 'app/models/interfaces/responseModel';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LegacyResponseModel } from 'app/models/interfaces/legacyResponseModel';
import { AuthExternalService } from 'app/services/authExternal.service';
import { AuthFirestoreService } from 'app/services/authFirestore.service';

export abstract class BaseService {

    constructor(private httpClient: HttpClient, private authService: AuthExternalService, public auth: AuthFirestoreService) {
    }

    protected getData(url: string): Promise<ResponseModel> {
        return this.httpClient.get(url, { headers: this.authService.getAuthHeaders() }).toPromise()
            .then((res) => this.extractData(res))
            .catch(this.handleError);
    }

    getDataBlob(url:string): Observable<Blob>{ return this.httpClient.get(url, { responseType: 'blob' });}

    post(url: string, model: any): Promise<ResponseModel> {
        return this.httpClient.post(url, model, { headers: this.authService.getAuthHeaders() }).toPromise()
            .then((res) => {
                return this.createResponseModel(res);
            }).catch(this.handleError);
    }

    patch(url: string, model?: any): Promise<ResponseModel> {
        return this.httpClient.patch(url, model, { headers: this.authService.getAuthHeaders() }).toPromise()
            .then((res) => {
                return this.createResponseModel(res);
            }).catch(this.handleError);
    }

    put(url: string, model: any): Promise<ResponseModel> {
        return this.httpClient.put(url, model, { headers: this.authService.getAuthHeaders() }).toPromise()
            .then((res) => {
                return this.createResponseModel(res);
            }).catch(this.handleError);
    }

    remove(url: string): Promise<ResponseModel> {
        return this.httpClient.delete(url, {  headers: this.authService.getAuthHeaders() }).toPromise()
            .then((res) => {
                return this.createResponseModel(res);
            }).catch(this.handleError);
    }

    protected isResponseModel(object: any): boolean {
        return object.hasOwnProperty('success');
    }

    isLegacyResponseModel(object: any): boolean {
        return (<LegacyResponseModel>object).Type !== undefined;
    }

    private extractData(res) {
        let responseModel: ResponseModel;
        if (this.isResponseModel(res)) {
            return <ResponseModel>res;
        } else {
            responseModel = new ResponseModel();
            responseModel.data = res;
            responseModel.success = true;
        }
        return responseModel;
    }

    protected extractDatat(res) {
        let responseModel: ResponseModel;
        if (this.isResponseModel(res)) {
            return <ResponseModel>res;
        } else {
            responseModel = new ResponseModel();
            responseModel.data = res;
            responseModel.success = true;
        }
        return responseModel;
    }

    protected handleError = (error: HttpErrorResponse): Promise<ResponseModel> => {
        let responseModel = new ResponseModel();
        responseModel.success = false;

        if (error.status === 500) {
            responseModel.message = 'There was an error calling the service';
        } else if (!this.isResponseModel(error.error)) {
            responseModel.message = 'take error text property';
        } else {
            responseModel = <ResponseModel>error.error;
        }
        return Promise.reject(responseModel);
    }

    private castLegacyResponseToResponseModel(response: any) {
        const responseModel = new ResponseModel();
        const legacyResponseModel = (<LegacyResponseModel>response);
        responseModel.message = legacyResponseModel.Message;
        responseModel.data = legacyResponseModel.TransactionNumber;
        responseModel.success = legacyResponseModel.Type === 1;
        return responseModel;
    }
    private castGenericResponseToResponseModel(response: any) {
        const responseModel = new ResponseModel();
        responseModel.data = response;
        responseModel.success = true;
        return responseModel;
    }

    private createResponseModel(response: any): ResponseModel {
        if (this.isLegacyResponseModel(response)) {
            return this.castLegacyResponseToResponseModel(response);
        } else if (!this.isResponseModel(response)) {
            return this.castGenericResponseToResponseModel(response);
        } else {
            return <ResponseModel>response;
        }
    }

    getUrl(): string {
        const compid = this.auth.currentUser.usrComid;
        switch (compid) {
          case '-Kutcf1qBWwZ7Qs84q-w':
             return 'https://torrecontroldev.alion.com.co:7103/api/';
            break;
          case 'SPX_ECO_COM_QA':
            return 'https://torrecontrolqa.alion.com.co:7203/api/';
            break;
          case 'SPX_ECO_COM_PROD':
            return 'https://torrecontrol.alion.com.co:7023/api/';
            break;
          default:
            return 'NOT-ALLOW-URL';
            break;
        }
    }
}
