import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';



const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import { AppHeaderModule, AppSidebarModule } from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

import { CanActivateUser } from './auth/can-activate';
import { Authorization } from './auth/authorization';
import { HttpClientModule } from '@angular/common/http';





import { NotifyService } from './services/notify.service';

// import {ConfirmDialogModule} from 'primeng/confirmdialog';
// import {ConfirmationService} from 'primeng/api';
// import { DialogModule } from 'primeng/dialog';






import { FIREBASE_CONFIG, FIREBASE_CONFIG1 } from './firebase.credentials';
import * as firebase from 'firebase';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthFirestoreService } from './services/authFirestore.service';
import { RequestFirestoreService } from './services/requestFirestore.service';


// import { CommondxModule } from './components/commondx/commondx.module';
import { RegisterComponent } from './pages/register/register.component';
import { NotifyTotalsService } from './services/notifytotals.service';
import { CommondialogModule } from './components/commondx/commondiag/commondialog.module';
import { VehicleService } from './services/vehicle.service';
import { AuthExternalService } from './services/authExternal.service';
import { DriverService } from './services/driver.service';
import { DocumentsService } from './services/documents.service';
import { LoadsService } from './services/loads.service';















firebase.initializeApp(FIREBASE_CONFIG);
firebase.firestore().settings({  }); // timestampsInSnapshots: true
firebase.firestore().enablePersistence();



@NgModule({
  imports: [

    CommondialogModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AppHeaderModule,
    AppSidebarModule,

    // FormsModule,
    PerfectScrollbarModule,
    // ConfirmDialogModule,
    // DialogModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    // NotifyComponent,
    // LoginComponent,
    RegisterComponent

  ],
  providers: [
    // ConfirmationService,
    NotifyService,
    NotifyTotalsService,
    AuthFirestoreService,
    RequestFirestoreService,
    VehicleService,
    LoadsService,
    DocumentsService,
    DriverService,
    AuthExternalService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    CanActivateUser,
    Authorization

  ],
  bootstrap: [
    AppComponent
  ],
  exports: [

  ]
})
export class AppModule { }
