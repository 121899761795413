import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DxBarGaugeComponent } from 'devextreme-angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { RequestFirestoreService } from './requestFirestore.service';
import { NotifyService } from './notify.service';

@Injectable()

export class AuthFirestoreService {

    public usuarioIsLog = false;
    public currentUser: any;
    private _tokenAKey = 'f';
    private _tokenBKey = 'x';
    private _userKey = 'usr';

    get tokenAKey() { return this._tokenAKey; }
    get tokenBKey() { return this._tokenBKey; }


    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private db: AngularFirestore,
        private requestfbservice: RequestFirestoreService,
        private ns: NotifyService
    ) {

    }

    loginUser(newEmail: string, newPassword: string): Promise<any> {
        return this.auth.auth
            .signInWithEmailAndPassword(newEmail, newPassword)
            .then(async usuario => {
                console.log('Usuario logra Ingresar a la aplicacion->', usuario);
                this.usuarioIsLog = true;
                // se debe consiultar el usuario interno para tener la info completa
                this.currentUser = await this.db
                    .collection('AppUsers')
                    .doc(usuario.user.uid)
                    .get().toPromise()
                    .then(usrObj => {
                        // tslint:disable-next-line:prefer-const
                        let userdb = usrObj.data();
                        userdb.key = usuario.user.uid;
                        console.log('lectura de usuario firebase: ', userdb);
                        sessionStorage.setItem('isOnline', usuario.user.uid);

                        this.saveCurrentUser(userdb);

                        return userdb;
                    });
                this.requestfbservice.getRequest(this.currentUser.usrCarrierId, this.currentUser.usrComid);
                this.requestfbservice.getAgreements_v2(this.currentUser.usrCarrierId, this.currentUser.usrComid);
                // this.requestfbservice.getliqDocs(this.currentUser.usrCarrierId, this.currentUser.usrComid);
                return {
                    code: '00',
                    desc: 'Usuario autenticado con éxito'
                };

            })
            .catch(err => {
                console.log('Error ingresando al portal->', err.code);
                return {
                    code: '01',
                    desc: 'La autenticacion del usuario ha fallado: ' + err.message
                };
            });
    }

    public saveCurrentUser(usrauth: any) {
        const tokenString = JSON.stringify(usrauth);
        const totalIndex = tokenString.length;
        const mediumIndex = Math.floor(totalIndex / 2);
        const tokenA = tokenString.substring(0, mediumIndex);
        const tokenB = tokenString.substring(mediumIndex);
        localStorage.setItem(this._tokenBKey, this.stringReverse(tokenB));
        localStorage.setItem(this._tokenAKey, this.stringReverse(tokenA));
    }

    public getCurrentUser(): any {
        const tokenA = this.stringReverse(localStorage.getItem(this._tokenAKey));
        const tokenB = this.stringReverse(localStorage.getItem(this._tokenBKey));
        if (tokenA && tokenB) {
            const str = tokenA.concat(tokenB);
            this.currentUser = JSON.parse(str);
            // sincroniza con fb
            this.requestfbservice.getRequest(this.currentUser.usrCarrierId, this.currentUser.usrComid);
            this.requestfbservice.getAgreements_v2(this.currentUser.usrCarrierId, this.currentUser.usrComid);
            // this.requestfbservice.getliqDocs(this.currentUser.usrCarrierId, this.currentUser.usrComid);
            return this.currentUser;
        } else {
            this.router.navigate(['/login']);


            return { code: '00' };
        }
    }

    public stringReverse(texto: string): string {
        if (texto) {
            return Array.from(texto).reverse().join('');
        }
    }

    getCurrentUserName() {
        return this.currentUser.usrName;
    }

    getCurrentCarrierName() {
        let carriername = this.currentUser.usrCarrierName;
        if (!carriername) {
            carriername = 'Transportador Genérico';
        }
        return carriername;
    }

    getCurrentMail() {
        return this.currentUser.usrMail;
    }

    resetPasswordUser() {

        const emailAddress = this.currentUser.usrMail;

        this.auth.auth.sendPasswordResetEmail(emailAddress).then(function () {
            // Email sent.
            this.ns.notify('Correo ok', 'Error', 3500);
        }).catch(function (error) {
            // An error happened.
            this.ns.notify('Correo no ok', 'Error', 3500);
        });
    }

    logOutUser(): Promise<any> {
        return this.auth.auth.signOut().then(() => {
            sessionStorage.removeItem('isOnline');
            console.log('Logout usuario');
        });
    }

}
