import { UserLoginModel } from '../models/user-login.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class Authorization {

    private server: string;
    // private server = 'http://10.77.12.150:3010/api/Login';
    private _isAuth: boolean;

    constructor(private http: HttpClient ) {

    }

    private headers = new HttpHeaders(
        {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    );

    authenticate(model: UserLoginModel): Promise<boolean> {
        return this.http.post(this.server, model).toPromise().then((resp: any) => {
            sessionStorage.setItem('currentUser', JSON.stringify(resp.data));
            return this._isAuth = resp.success;
        }, err => {
            console.error('Error: ', err);
        }).catch(err => {
            console.error('Error: ', err);
        });
    }

    getCurrentUser() {
        return JSON.parse(sessionStorage.getItem('currentUser'));
    }

    getCurrentSession() {
        return JSON.parse(sessionStorage.getItem('currentUser')).session;
    }

    getCurrentUserName() {
        return JSON.parse(sessionStorage.getItem('currentUser')).name;
    }

    getCurrentUserSubsidiary() {
        return JSON.parse(sessionStorage.getItem('currentUser')).subsidiary;
    }

    getAuthHeaders() {
        return this.headers;
    }

    isAuth() {
        return this._isAuth;
    }

    logOff() {
        this._isAuth = false;
    }
}
