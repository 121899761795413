import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotifyTotalsModel } from 'app/models/notify-totals.model';

@Injectable()
export class NotifyTotalsService {

    public notice: Subject<NotifyTotalsModel> = new Subject<NotifyTotalsModel>();

    notify(totalRequest: number, totalAgree: number) {
        this.notice.next({ totalRequest, totalAgree });
    }
}
