export const navItems = [
  {
    name: 'Dashboard Operación',
    url: '/home/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Bolsa Disponible',
    url: '/home/request2',
    icon: 'icon-bell',
    badge: {
      variant: 'secondary',
      text: ' [0] '
    }
  },
  {
    name: 'Mis Viajes',
    url: '/home/agree',
    icon: 'icon-pin',
    badge: {
      variant: 'secondary',
      text: ' [0] '
    }
   },
   {
    name: 'Estado de Cuenta',
    url: '/home/xx',
    icon: 'icon-layers'
   }
  // {
  //   name: 'Cargues Masivos',
  //   url: '/masive-loads',
  //   icon: 'icon-layers'
  // }
];
