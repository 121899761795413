import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists
} from 'angularfire2/firestore';
import { DxBarGaugeComponent } from 'devextreme-angular';
import {
  map,
  tap,
  take,
  switchMap,
  mergeMap,
  expand,
  takeWhile
} from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { navItems } from '../_nav';
import { NotifyTotalsService } from './notifytotals.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';



@Injectable()

export class RequestFirestoreService {

  public requestBase = [];
  public agreementBase = [];
  public agreementBaseOperative = [];
  public liqdocs = [];

  // parametros para realizar los filtros y ordenamiento

  public orderType = 0; // 0 asc, 1 desc
  public reqPlaceBid = 2; // 0 sin, 1 con, 2 todas
  public originField = '';
  public destinField = '';
  public totalRequest = 0;
  public totalAgreements = 0;
  public totalAgreementsOperative = 0;
  public navItems = navItems;
  public dbExp;

  constructor(
    public db: AngularFirestore,
    private http: HttpClient,
    private nst: NotifyTotalsService,
    private messageService: MessageService
  ) {

    // const request = indexedDB.open('DataBaseViewerRequest');
    // request.onerror = function (event) {
    //   alert('Why didn\'t you allow my web app to use IndexedDB?!');
    // };
    // this.dbExp = request.onsuccess = function (event) {
    //   const dbObj = request.result;
    //   dbObj.onerror = function (event1) {
    //     // Generic error handler for all errors targeted at this database's
    //     // requests!
    //     alert('Database error: ' + JSON.stringify(event1));
    //   };
    //   return dbObj;
    // };

  }

  public getRequest(carrier, company) {
    console.log('x1 carrier a cleer->', company);
    console.log('x1 carrier a leer->', carrier);
    this.db
      .collection('Tendering')
      .doc(company)
      .collection('Requests')
      .doc(carrier)
      .collection('Singleweb')
      .snapshotChanges()
      .pipe(
        map(items => {
          // .            // <== new way of chaining
          return items.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            const isVisible = true;
            let isTargetPlate = false;
            if (data.triesInfo) {
              const trtmp = data.triesInfo;
              data.triesInfo = [];
              data.triesInfo = this.sortArrayTries(trtmp);
            }
            if ( data.targetPlate ) {
              isTargetPlate = true;
            }
            return { key, isVisible, isTargetPlate, ...data }; // or {key, ...data} in case data is Obj
          });
        })
      )
      .subscribe(elem => {
        console.log('llego **->>>>', elem, company);
        elem.forEach((elem1: any) => {
          // console.log ('prueba->', elem1);
          const objtemp = elem1;
          if (objtemp.triesInfo) {

            const target = objtemp.triesInfo[0];
            if (elem1.reqStatus === 10 && target.state === 20) {
              // tslint:disable-next-line:max-line-length
              // this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Ref:' + objtemp.externalReference, detail: target.msg, life: 7000 });
            }
          }
        });
        this.requestBase = elem;
        if (this.orderType === 1) {
          this.requestBase.sort(function (a, b) {
            if (a.creationDate > b.creationDate) {
              return 1;
            }
            if (a.creationDate < b.creationDate) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        } else {
          this.requestBase.sort(function (a, b) {
            if (a.creationDate > b.creationDate) {
              return -1;
            }
            if (a.creationDate < b.creationDate) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });
        }

        // admite oferta
        // // 0 sin, 1 con, 2 todas
        switch (this.reqPlaceBid) {
          case 0:
            this.requestBase.forEach(elem1 => {
              // console.log ('prueba->', elem1);
              if (this.obtieneRequiereContraOferta(elem1)) {
                elem1.isVisible = false;
              } else {
                elem1.isVisible = true;
              }
              elem1.isVisible = this.esVisiblexOrigenDestino(elem1);
            });
            break;
          case 1:
            this.requestBase.forEach(elem1 => {
              // console.log ('prueba->', elem1);
              if (this.obtieneRequiereContraOferta(elem1)) {
                elem1.isVisible = true;
              } else {
                elem1.isVisible = false;
              }
              elem1.isVisible = this.esVisiblexOrigenDestino(elem1);
            });
            break;
          case 2:
            this.requestBase.forEach(elem1 => {
              // console.log ('prueba->', elem1);
              if (this.obtieneRequiereContraOferta(elem1)) {
                elem1.isVisible = true;
              } else {
                elem1.isVisible = true;
              }
              elem1.isVisible = this.esVisiblexOrigenDestino(elem1);
            });
            break;
        }



        // se impide que se vean las solicitudes para placa

        // actualiza los contadores del menu
        this.totalRequest = elem.length;
        this.updatePrincipalMenu();

      });

  }

  // coloca como vistos todos los mensajes
  public setRequestView(carrier, company, requestkey, request) {
    console.log('Objeto -> ', company, carrier, requestkey,  request);
   return this.db
    .collection('Tendering')
    .doc(company)
    .collection('Requests')
    .doc(carrier)
    .collection('Singleweb')
    .doc(requestkey)
    .set(request)
    .then( val => {
      console.log('Proceso oK', val);
      return val;
    }, error => {
      console.log('Errorrrrr');
    });

  }




  public getAgreements_v2(carrier, company) {
    console.log('x1 usuario a leer cagree->', company);
    console.log('x1 usuario a leer agree->', carrier);
    const fechaact = moment().add(-2, 'month').unix();
    const fechatimestamp = new firebase.firestore.Timestamp(fechaact, 0);
    console.log('fecha', fechaact);

    this.db
    .collection('Tendering')
    .doc(company)
    .collection('Agreements')
    .doc(carrier)
    .collection('Singleweb', ref => ref.orderBy('creationDate', 'desc').endAt(fechaact))
      .snapshotChanges()
      .pipe(
        map(items => {
          // .            // <== new way of chaining
          const isVisible = true;
          return items.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, isVisible, ...data }; // or {key, ...data} in case data is Obj
          });
        })
      )
      .subscribe(elem => {
        console.log('retorna<<<<<<->', elem);

        this.agreementBaseOperative = [];
        this.agreementBase = [];
        elem.forEach((elem1: any) => {
          // console.log ('prueba->', elem1);
          if (
            elem1.reqexternalcodestate !== '-80' &&
            elem1.reqexternalcodestate !== 80 &&
            elem1.reqexternalcodestate !== '80' &&
            elem1.reqexternalcodestate !== '-99' &&
            elem1.reqexternalcodestate !== '-90' &&
            elem1.reqexternalcodestate !== -99 &&
            elem1.reqexternalcodestate !== -90
            ) {
            this.agreementBaseOperative.push(elem1);
          } else {
            // if (elem1.reqexternalcodestate === '80' ||  elem1.reqexternalcodestate === 80) {
              this.agreementBase.push(elem1);
            // }
          }
        });
        this.totalAgreements = this.agreementBase.length;

        // this.agreementBase = elem;
        this.totalAgreementsOperative = this.agreementBaseOperative.length;
         // actualiza los contadores del menu

         this.updatePrincipalMenu();
      });
  }

  public getAgreements(usrId) {
    console.log('usuario a leer agree->', usrId);
    this.db
      .collection('AppUsers')
      .doc(usrId)
      .collection('Agreements')
      .snapshotChanges()
      .pipe(
        map(items => {
          // .            // <== new way of chaining
          return items.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data }; // or {key, ...data} in case data is Obj
          });
        })
      )
      .subscribe(elem => {

        elem.forEach(elem1 => {
          // console.log ('prueba->', elem1);
        });
        this.agreementBase = elem;
        this.totalAgreements = elem.length;
      });
  }

  public getliqDocs(carrier, company) {
    this.db
    .collection('bandeja')
    .doc(company)
    .collection('documentos')
    .doc(carrier)
    .collection('preliquidaciones')
      .snapshotChanges()
      .pipe(
        map(items => {
          // .            // <== new way of chaining
          return items.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data }; // or {key, ...data} in case data is Obj
          });
        })
      )
      .subscribe(elem => {

        elem.forEach(elem1 => {
          // console.log ('prueba->', elem1);
        });
        this.liqdocs = elem;

      });
  }

  public getliqDocsFiltered(carrier: string, company: string, year: number, month: number) {
  
    this.db
      .collection('bandeja')
      .doc(company)
      .collection('documentos')
      .doc(carrier)
      .collection('preliquidaciones')
      .snapshotChanges()
      .pipe(
        map(items => {
       
          // .            // <== new way of chaining
          return items.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data }; // or {key, ...data} in case data is Obj
          });
        })
      )
      .subscribe(elem => {

       console.log(elem);
        const liqdocsFiltered = [];
        elem.forEach((liqdoc: any) => {
        
          const parts = liqdoc.fechacreacion.split('/');
          const lqmonth = Number(parts[1].trim());
          const lqyear = Number(parts[2].slice(0, 4).trim());
         // const lqvalue=Number(liqdoc.valortotal);
          //liqdoc.valortotal =  lqvalue;
      
          if (lqmonth === Number(month) && lqyear === Number(year)) {
          
            liqdocsFiltered.push(liqdoc);
          }
       
        });
        console.log(liqdocsFiltered);
        this.liqdocs = liqdocsFiltered;
      });
  }



  sortArrayTries(arr: any[]) {
    console.log('arreglo->', arr);
    return arr.sort(function (a, b) {
      if (a.tryDate > b.tryDate) {
        return -1;
      }
      if (a.tryDate < b.tryDate) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
  }

  updatePrincipalMenu() {
    console.log('actualiza menu', this.totalRequest);
    this.nst.notify(this.totalRequest, this.totalAgreements);
    // this.navItems = navItems;
    // setTimeout(() => {
    //   console.log('actualiza menu', this.totalRequest);
    //   this.navItems = [{
    //     name: 'Dashboard Operación',
    //     url: '/home/dashboard',
    //     icon: 'icon-speedometer'
    //   },
    //   {
    //     name: 'Bolsa Disponible*',
    //     url: '/home/request2',
    //     icon: 'icon-bell',
    //     badge: {
    //       variant: 'secondary',
    //       text: ' [' + this.totalRequest + '] '
    //     }
    //   },
    //   {
    //     name: 'Mis Viajes',
    //     url: '/home/agree',
    //     icon: 'icon-pin',
    //     badge: {
    //       variant: 'secondary',
    //       text: ' [10] '
    //     }
    //   },
    //   {
    //     name: 'Estado de Cuenta',
    //     url: '/home/xx',
    //     icon: 'icon-layers'
    //   }];
    // }, 1000);
  }



  esVisiblexOrigenDestino(e) {
    let resultado = true;

    // theString.match(/^.*abc$/)
    // solo origen
    if (this.originField !== '' && this.destinField === '') {
      const tmp: string = e.fromCity;
      if (tmp.toLowerCase().includes(this.originField.toLowerCase())) {
        resultado = true;
      } else {
        resultado = false;
      }
      return resultado;
    }

    // solo destino
    if (this.originField === '' && this.destinField !== '') {
      const tmp: string = e.toCity;
      if (tmp.toLowerCase().includes(this.destinField.toLowerCase())) {
        resultado = true;
      } else {
        resultado = false;
      }
      return resultado;
    }

    // origen -destino
    if (this.originField !== '' && this.destinField !== '') {
      const tmp: string = e.fromCity;
      const tmp1: string = e.toCity;
      if (tmp.toLowerCase().includes(this.originField.toLowerCase()) && tmp1.toLowerCase().includes(this.destinField.toLowerCase())) {
        resultado = true;
      } else {
        resultado = false;
      }

    }

    return resultado;


  }



  obtieneRequiereContraOferta(e) {
    let result = false;
    if (e.rates.canPlaceBidUp || e.rates.canPlaceBidDown) {
      result = true;
    }
    return result;
  }



  public verificaRecursos(vehcode, trailercode, drivercode, appusercomid): Promise<any> {
    const obj = {
      'appusercomid': appusercomid,
      'vehiclecode': vehcode,
      'trailercode': trailercode,
      'drivercode': drivercode
    };
    // const headers1 = new Headers({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers1 });
    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json'
      }
    );
    console.log('el objeto de llamado es->', obj);
    return this.http.post('https://us-central1-spxgo-ccd34.cloudfunctions.net/verificaVehPortal', obj, { headers: headers })
      .toPromise().then(resultado => {
        console.log('el resultado es -> ', resultado);
        return resultado;
      });
  }

  /** Crea un intento de acuerdo desde el portal */
  public crearIntentoAcuerdo(vehcode, trailercode, drivercode, appusercomid, appuserid, reqobj): Promise<any> {
    // const appuserid = req.body.appuserid;
    // const appusercomid = req.body.appusercomid;
    // const reqkey = req.body.reqkey;
    // const requ = req.body.requ;
    // const vehplate = req.body.vehplate;
    // const trplate = req.body.trplate;
    // const driverid = req.body.driverid;

    const obj = {
      'appuserid': appuserid,
      'appusercomid': appusercomid,
      'reqkey': reqobj.key,
      'requ': reqobj,
      'vehplate': vehcode,
      'trplate': trailercode,
      'driverid': drivercode
    };

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json'
      }
    );

    console.log('el objeto de llamado es->', obj);
    return this.http.post(' https://us-central1-spxgo-ccd34.cloudfunctions.net/crearIntentoAcuerdoPortal', obj, { headers: headers })
      .toPromise().then(resultado => {
        console.log('el resultado es -> ', resultado);
        return resultado;
      }).catch(res => {
        console.log('error consumo->', res);
        return {
          type: 'Error',
          msg: JSON.stringify(res.error),
          uid: ''
        };
      });
  }





  public reagendaAcuerdo(appuserid, appusercomid, reqkey, requ, fechabase) {
    // https://us-central1-spxgo-ccd34.cloudfunctions.net/reagendaCitaWeb
    // const appuserid = req.body.appuserid;
    // const appusercomid = req.body.appusercomid;
    // const reqkey = req.body.reqkey;
    // const requ = req.body.requ;
    // const fechabase = req.body.fechabase;
    const obj = {
      'appuserid': appuserid,
      'appusercomid': appusercomid,
      'reqkey': reqkey,
      'requ': requ,
      'fechabase': fechabase
    };

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json'
      }
    );

    console.log('el objeto de llamado es->', obj);
    return this.http.post('https://us-central1-spxgo-ccd34.cloudfunctions.net/reagendaCitaWeb', obj, { headers: headers })
      .toPromise().then(resultado => {
        console.log('el resultado es -> ', resultado);
        return resultado;
      }).catch(res => {
        console.log('error consumo->', res);
        return {
          type: 'Error',
          msg: JSON.stringify(res.error),
          uid: ''
        };
      });

  }

  public cancelarAcuerdo(appuserid, appusercomid, reqkey, requ) {
    // https://us-central1-spxgo-ccd34.cloudfunctions.net/cancerlarContratacionApp
    // const appuserid = req.body.appuserid;
    // const reqkey = req.body.reqkey;

    // const appuserid = req.body.appuserid;
    // const appusercomid = req.body.appusercomid;
    // const reqkey = req.body.reqkey;
    // const requ = req.body.requ;


    const obj = {
      'appuserid': appuserid,
      'appusercomid': appusercomid,
      'reqkey': reqkey,
      'requ': requ
    };

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json'
      }
    );

    console.log('el objeto de llamado es->', obj);
    return this.http.post(' https://us-central1-spxgo-ccd34.cloudfunctions.net/cancelarAcuerdoWeb', obj, { headers: headers })
      .toPromise().then(resultado => {
        console.log('el resultado es -> ', resultado);
        return resultado;
      }).catch(res => {
        console.log('error consumo->', res);
        return {
          type: 'Error',
          msg: JSON.stringify(res.error),
          uid: ''
        };
      });

  }


  /** v2 del portal **/
  // old //
  public async crearIntentoAcuerdo_v2(vehcode, trailercode, drivercode, appusercomid, appuserid, reqobj, carrier, bid): Promise<any> {
    // const appuserid = req.body.appuserid;
    // const appusercomid = req.body.appusercomid;
    // const reqkey = req.body.reqkey;
    // const requ = req.body.requ;
    // const vehplate = req.body.vehplate;
    // const trplate = req.body.trplate;
    // const driverid = req.body.driverid;

    // const appuserid = req.body.appuserid;
    // const appusercomid = req.body.appusercomid;
    // const reqkey = req.body.reqkey;
    // const requ = req.body.requ;
    // const vehplate = req.body.vehplate;
    // const trplate = req.body.trplate;
    // const driverid = req.body.driverid;
    // const bid = req.body.bid;
    // const carrier = req.body.carrier;

    console.log('******x3', reqobj);

    // debemos buscar la cia
    // para obetner el endpoint
    // para crear el request

    const companiaseleccionada = await this.db
      .collection('Companies')
      .doc(appusercomid)
      .get()
      .toPromise()
      .then(function (doc) {
        const compsel = doc.data();
        return compsel;
      });





    const obj = {
      'appuserid': appuserid,
      'appusercomid': appusercomid,
      'request': reqobj.key,
      'requestObject': reqobj,
      'vehiclecode': vehcode,
      'trailercode': trailercode,
      'drivercode': drivercode,
      'carriercode': carrier,
      'action': 'create',
      'bid': bid
    };

    console.log('******x4', obj);

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json'
      }
    );

    console.log('el objeto de llamado es->', obj);
    return this.http.post('https://us-central1-spxgo-ccd34.cloudfunctions.net/crearIntentoAcuerdo_v2', obj, { headers: headers })
      .toPromise().then(resultado => {
        console.log('el resultado es -> ', resultado);
        return resultado;
      }).catch(res => {
        console.log('error consumo->', res);
        return {
          type: 'Error',
          msg: JSON.stringify(res.error),
          uid: ''
        };
      });
  }

  /** current */
  public async crearIntentoAcuerdo_v2a(
    vehcode,
    trailercode,
    drivercode,
    appusercomid,
    appuserid,
    reqobj,
    carrier,
    bid,
    operation): Promise<any> {
    console.log('Inicio de Proceso');
    if (!trailercode) {
      trailercode = '';
    }
    // validaciones
    if (!vehcode || vehcode === '') {
      const msg = 'La placa no puede estar vacia';
      const respuesta1 = {
        type: 'err',
        msg: msg,
        uid: ''
      };
      console.log(vehcode);
      return Promise.resolve(respuesta1);
    }

    if (!drivercode || drivercode === '') {
      const msg = 'La identificación del conductor no puede estar vacia';
      const respuesta1 = {
        type: 'err',
        msg: msg,
        uid: ''
      };
      console.log(msg);
      return Promise.resolve(respuesta1);
    }

    let typeBr = 1;
    if (bid >= 0) {
      typeBr = 2;
    }


    const companiaseleccionada = await this.db
    .collection('Companies')
    .doc(appusercomid)
    .get()
    .toPromise()
    .then(function (doc) {
      const compsel = doc.data();
      return compsel;
    });


    const agrId =
    carrier + "_" + reqobj.externalReference + "_" + Date.now().valueOf();
    const registroBroker = {
      type: typeBr, // 1 - newAgreement, 2 - newbid
      operation: operation,
      obj: reqobj,
      state: 10,
      messageResult: '--',
      vehplate: vehcode,
      trplate: trailercode,
      driverid: drivercode,
      compUri: companiaseleccionada.compUriReportAgreementWeb,
      bid: bid,
      creationDate: firebase.firestore.FieldValue.serverTimestamp(),
      appuserid: appuserid,
      appusercomid: appusercomid,
      carrier: carrier,
      reference: reqobj.externalReference,
      externalref: agrId
    };


// insertar la operacion en la colección swap

const idswap = await this.db
.collection("Tendering")
.doc(appusercomid)
.collection("Swap")
.doc(carrier)
.collection("Singleweb")
.doc(agrId)
.set(registroBroker)
.then((elem) => {
  // console.log('El id de la solicitud creada es: ', agrId);
  return Promise.resolve(agrId);
});

    console.log('paso 2 proceso broker');

    let triesInfoTmp = reqobj.triesInfo;
    console.log('paso3');
    if (!triesInfoTmp) {
      triesInfoTmp = [];
    }

    console.log('paso4');
    if (!trailercode) {
      trailercode = '';
    }

    const objtry = {
      vehicle: vehcode,
      trailler: trailercode,
      driver: drivercode,
      state: 20,
      bid: bid,
      tryDate: Date.now() / 1000 // firebase.firestore.FieldValue.serverTimestamp()
    
    };

    console.log('paso5');
    // triesInfoTmp.push(objtry);
    console.log('paso6');


    const objmod = {
      reqStatus: 20, // en contratacion
      triesInfo: triesInfoTmp,
      idswap: agrId,
    };
    if (typeBr === 2) {
      objmod.reqStatus = 50;
      objtry.state = 50;
    }

    triesInfoTmp.push(objtry);
    console.log('paso 8');

    return await this.db
      .collection('Tendering')
      .doc(appusercomid)
      .collection('Requests')
      .doc(carrier)
      .collection('Singleweb')
      .doc(reqobj.externalReference)
      .update(objmod).then(valor => {
      
        return this.db.collection('BrokerCollection')
          .doc(appusercomid)
          .collection('Agreements')
          .doc(agrId)
          .set(registroBroker)
          .then(tareac => {
            const respuesta1 = {
              type: 'msg',
              msg: 'Solicitud creada con éxito.',
              uid: agrId
            };
            console.log('El id de la solicitud creada es: ', agrId);
            return Promise.resolve(respuesta1);
          }).catch(errorm => {
            const msg = 'Error  solicitud: ' + JSON.stringify(errorm);
            const respuesta1 = {
              type: 'err',
              msg: msg,
              uid: agrId
            };
            console.log(msg);
            return Promise.resolve(respuesta1);
          });
      }).catch(errorm => {
        const msg = 'Error ejecutando operación: ' + errorm.code;
        const respuesta1 = {
          type: 'err',
          msg: msg,
          uid: agrId
        };
        console.log(msg);
        return Promise.resolve(respuesta1);
      });

  }

  public async cancelarOferta_v2a(appusercomid, reqobj, carrier): Promise<any> {
    const registroBroker = {
      type: 2, // 1 - newAgreement, 2 - newbid
      operation: 'DEL',
      obj: reqobj,
      state: 10,
      carrier: carrier,
      messageResult: '--',
      reference: reqobj.externalReference,
      vehplate: '',
      trplate: '',
      driverid: '',
      bid: 0,
      creationDate: firebase.firestore.FieldValue.serverTimestamp()
    };

    const agrId = carrier + '_' + reqobj.externalReference + '_' + Date.now().valueOf();
    console.log('paso 2 proceso broker');

    let triesInfoTmp = reqobj.triesInfo;
    console.log('paso3');
    if (!triesInfoTmp) {
      triesInfoTmp = [];
    }

    console.log('paso4');

    const objtry = {
      vehicle: '',
      trailler: '',
      driver: '',
      state: 60,
      bid: 0,
      tryDate: Date.now() / 1000 // firebase.firestore.FieldValue.serverTimestamp()
    };

    console.log('paso5');


    // objmod.reqStatus = 50;
    // objtry.state = 50;
    triesInfoTmp.push(objtry);
    console.log('paso 8');

    return await this.db
      .collection('Tendering')
      .doc(appusercomid)
      .collection('Requests')
      .doc(carrier)
      .collection('Singleweb')
      .doc(reqobj.externalReference)
      .update({ reqStatus: -89, triesInfo: triesInfoTmp }).then(valor => {
        return this.db.collection('BrokerCollection')
          .doc(appusercomid)
          .collection('Agreements')
          .doc(agrId)
          .set(registroBroker)
          .then(tareac => {
            const respuesta1 = {
              type: 'msg',
              msg: 'Solicitud creada con éxito.',
              uid: agrId
            };
            console.log('El id de la solicitud creada es: ', agrId);
            return Promise.resolve(respuesta1);
          }).catch(errorm => {
            const msg = 'Error  solicitud: ' + JSON.stringify(errorm);
            const respuesta1 = {
              type: 'err',
              msg: msg,
              uid: agrId
            };
            console.log(msg);
            return Promise.resolve(respuesta1);
          });
      }).catch(errorm => {
        const msg = 'Error ejecutando operación: ' + errorm.code;
        const respuesta1 = {
          type: 'err',
          msg: msg,
          uid: agrId
        };
        console.log(msg);
        return Promise.resolve(respuesta1);
      });


  }

  public async actualizaRecursos_v2a(appusercomid, reqobj, carrier, vehplate, trplate, driverid, appuserid) {

    const companiaseleccionada = await this.db
    .collection('Companies')
    .doc(appusercomid)
    .get()
    .toPromise()
    .then(function (doc) {
      const compsel = doc.data();
      return compsel;
    });
    const agrId = carrier + '_' + reqobj.externalReference + '_' + Date.now().valueOf();
    const registroBroker = {
      type: 1, // 1 - newAgreement, 2 - newbid
      operation: 'UPD',
      obj: reqobj,
      state: 10,
      messageResult: '--',
      reference: reqobj.externalReference,
      compUri: companiaseleccionada.compUriReportAgreementWeb,
      vehplate: vehplate,
      trplate: trplate,
      driverid: driverid,
      bid: 0,
      carrier: carrier,
      appuserid: appuserid,
      appusercomid: appusercomid,
      creationDate: firebase.firestore.FieldValue.serverTimestamp(),
      externalref: reqobj.idswap
    };

  
    console.log('paso 2 proceso broker');

    let triesInfoTmp = reqobj.triesInfo;
    console.log('paso3');
    if (!triesInfoTmp) {
      triesInfoTmp = [];
    }

    console.log('paso4');

    const objtry = {
      vehicle: '',
      trailler: '',
      driver: '',
      state: 60,
      bid: 0,
      tryDate: Date.now() / 1000 // firebase.firestore.FieldValue.serverTimestamp()
    };

    console.log('paso5');


    // objmod.reqStatus = 50;
    // objtry.state = 50;
    triesInfoTmp.push(objtry);
    console.log('paso 8', reqobj.externalReference);

    return await this.db
      .collection('Tendering')
      .doc(appusercomid)
      .collection('Agreements')
      .doc(carrier)
      .collection('Singleweb')
      .doc(reqobj.externalReference)
      .update({ reqStatus: 30, triesInfo: triesInfoTmp }).then(valor => {
        return this.db.collection('BrokerCollection')
          .doc(appusercomid)
          .collection('Agreements')
          .doc(agrId)
          .set(registroBroker)
          .then(tareac => {
            const respuesta1 = {
              type: 'msg',
              msg: 'Solicitud en curso.',
              uid: agrId
            };
            console.log('El id de la solicitud creada es: ', agrId);
            return Promise.resolve(respuesta1);
          }).catch(errorm => {
            const msg = 'Error  solicitud: ' + JSON.stringify(errorm);
            const respuesta1 = {
              type: 'err',
              msg: msg,
              uid: agrId
            };
            console.log(msg);
            return Promise.resolve(respuesta1);
          });
      }).catch(errorm => {
        const msg = 'Error ejecutando operación: ' + errorm.code;
        const respuesta1 = {
          type: 'err',
          msg: msg,
          uid: agrId
        };
        console.log(msg);
        return Promise.resolve(respuesta1);
      });


  }

  public async cancelarAcuerdo_v2a(appusercomid, reqobj, carrier, appuserid): Promise<any> {

    const companiaseleccionada = await this.db
    .collection('Companies')
    .doc(appusercomid)
    .get()
    .toPromise()
    .then(function (doc) {
      const compsel = doc.data();
      return compsel;
    });





    const registroBroker = {
      type: 1, // 1 - newAgreement, 2 - newbid
      operation: 'DEL',
      obj: reqobj,
      state: 10,
      messageResult: '--',
      reference: reqobj.externalReference,
      compUri: companiaseleccionada.compUriReportAgreementWeb,
      vehplate: '',
      trplate: '',
      driverid: '',
      bid: 0,
      carrier: carrier,
      appuserid: appuserid,
      appusercomid: appusercomid,
      creationDate: firebase.firestore.FieldValue.serverTimestamp()
    };

    const agrId = carrier + '_' + reqobj.externalReference + '_' + Date.now().valueOf();
    console.log('paso 2 proceso broker');

    let triesInfoTmp = reqobj.triesInfo;
    console.log('paso3');
    if (!triesInfoTmp) {
      triesInfoTmp = [];
    }

    console.log('paso4');

    const objtry = {
      vehicle: '',
      trailler: '',
      driver: '',
      state: 60,
      bid: 0,
      tryDate: Date.now() / 1000 // firebase.firestore.FieldValue.serverTimestamp()
    };

    console.log('paso5');


    // objmod.reqStatus = 50;
    // objtry.state = 50;
    triesInfoTmp.push(objtry);
    console.log('paso 8', reqobj.externalReference);

    return await this.db
      .collection('Tendering')
      .doc(appusercomid)
      .collection('Agreements')
      .doc(carrier)
      .collection('Singleweb')
      .doc(reqobj.externalReference)
      .update({ reqStatus: -89, triesInfo: triesInfoTmp }).then(valor => {
        return this.db.collection('BrokerCollection')
          .doc(appusercomid)
          .collection('Agreements')
          .doc(agrId)
          .set(registroBroker)
          .then(tareac => {
            const respuesta1 = {
              type: 'msg',
              msg: 'Solicitud en curso.',
              uid: agrId
            };
            console.log('El id de la solicitud creada es: ', agrId);
            return Promise.resolve(respuesta1);
          }).catch(errorm => {
            const msg = 'Error  solicitud: ' + JSON.stringify(errorm);
            const respuesta1 = {
              type: 'err',
              msg: msg,
              uid: agrId
            };
            console.log(msg);
            return Promise.resolve(respuesta1);
          });
      }).catch(errorm => {
        const msg = 'Error ejecutando operación: ' + errorm.code;
        const respuesta1 = {
          type: 'err',
          msg: msg,
          uid: agrId
        };
        console.log(msg);
        return Promise.resolve(respuesta1);
      });


  }

}
