import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthFirestoreService } from '../services/authFirestore.service';


@Injectable()
export class CanActivateUser implements CanActivate {

    constructor( private rtr: Router, private auth1: AuthFirestoreService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {

        const isOnline = sessionStorage.getItem('isOnline');
        console.log('entro a validar->', isOnline);
        if (isOnline) {
            const tmpusr = this.auth1.getCurrentUser();
            console.log('usuario login->', tmpusr.usrName);
            return true;
        } else {
            this.rtr.navigate(['/login']);
            return false;
        }
    }
}
