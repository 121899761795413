import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'app/models/interfaces/responseModel';
import { BaseService } from 'app/models/interfaces/baseService';
import { AuthExternalService } from './authExternal.service';
import { environment } from '../../environments/environment.prod';
import { AuthFirestoreService } from './authFirestore.service';


@Injectable({
  providedIn: 'root'
})
export class DriverService extends BaseService {

  apiUrl = '';
  trafficapiUrl = environment.trafficApiUrl;

  constructor(private http: HttpClient, private autService: AuthExternalService, public auth: AuthFirestoreService) {
    super(http, autService, auth);
    this.apiUrl = this.getUrl();
  }

  getAllDrivers(carrierId: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}drivers/GetDriversByCarrieCode/${carrierId}`);
  }

  getAllIdTypes(): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}/drivers/GetIdentificationTypes`);
  }

  inactiveDriver(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/drivers/InactiveDriver`, model);
  }

  activeDriver(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/drivers/ActiveDriver`, model);
  }

  addDriver(model: any): Promise<ResponseModel> {
    return this.post(`${this.apiUrl}/drivers/AddDriver`, model);
  }

  editDriver(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/drivers/UpdateDriver`, model);
  }

}
