import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'app/models/interfaces/responseModel';
import { BaseService } from 'app/models/interfaces/baseService';
import { AuthExternalService } from './authExternal.service';
import { environment } from '../../environments/environment.prod';
import { AuthFirestoreService } from './authFirestore.service';


@Injectable({
  providedIn: 'root'
})
export class VehicleService extends BaseService {

  apiUrl = ' ';
  trafficapiUrl = environment.trafficApiUrl;

  constructor(private http: HttpClient, private autService: AuthExternalService, public auth: AuthFirestoreService) {
    super(http, autService, auth);
    this.apiUrl = this.getUrl();
  }

  getAllVehicles(carrierId: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}vehicles/GetVehiclesByCarrieCode/${carrierId}`);
  }

  getAllTrailers(carrierId: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}vehicles/GetTrailersByCarrieCode/${carrierId}`);
  }

  getGpsInfoByVehCode(vehicle: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}vehicles/GetGpsInfoByVehCode/${vehicle}`);
  }

  getGpsProviders(): Promise<ResponseModel> {
    return this.getData(`${this.trafficapiUrl}/gps/providers`);
  }

  getAllTypes(): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}/vehicles/GetVehicleTypes`);
  }

  getAllBodyWorks(): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}/vehicles/GetVehicleBodyworks`);
  }

  addVehicle(model: any): Promise<ResponseModel> {
    return this.post(`${this.apiUrl}/vehicles/AddVehicle`, model);
  }

  editVehicle(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/vehicles/UpdateVehicle`, model);
  }

  inactiveVehicle(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/vehicles/InactiveVehicle`, model);
  }

  activeTrailer(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/vehicles/ActiveTrailer`, model);
  }

  addTrailer(model: any): Promise<ResponseModel> {
    return this.post(`${this.apiUrl}/vehicles/AddTrailer`, model);
  }

  editTrailer(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/vehicles/UpdateTrailer`, model);
  }
}
