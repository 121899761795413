import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthExternalService {

    apiUrlRoot: string;
    apiBopisUrlRoot: string;
    public browseLang = 'es';

    constructor() { }


    getAuthHeaders() {
        return new HttpHeaders(
            {
                'Authorization': 'Basic VENCMkJBbGlvbjpCMkI0bDEwTg=='
            }
        );
    }

}
