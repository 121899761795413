import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'app/models/interfaces/responseModel';
import { BaseService } from 'app/models/interfaces/baseService';
import { AuthExternalService } from './authExternal.service';
import { environment } from '../../environments/environment.prod';
import { AuthFirestoreService } from './authFirestore.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoadsService extends BaseService {

  apiUrl = '';
  trafficapiUrl = environment.trafficApiUrl;

  constructor(private http: HttpClient, private autService: AuthExternalService, public auth: AuthFirestoreService) {
    super(http, autService, auth);
    this.apiUrl = this.getUrl();
  }

  getFinishLoadsByCarrierCode(carrierId: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}loads/GetFinishLoadsByCarrierCode/${carrierId}`);
  }

  getFinishLoadsByCarrierCodeDates(carrierId: any, DateIni: any, DateFin: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}loads/GetFinishLoadsByCarrierCodeDates/${carrierId}/${DateIni}/${DateFin}`);
  }

  getCurrentLoadsByCarrierCode(carrierId: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}loads/GetCurrentLoadsByCarrierCode/${carrierId}`);
  }

  getLoadDetail(loanumber: any): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}loads/GetLoadPortal/${loanumber}`);
  }

  getLoadTicket(loanumber: any):  Observable<Blob> {
    return this.getDataBlob(`${this.apiUrl}loads/GetTicketLoadPortal/${loanumber}`);
  }

}
