export class VersionAppModel {
    version: string;
    public constructor(init?: Partial<VersionAppModel>) {
        Object.assign(this, init);
        this.version = '2.1.0';

        // version 2.0.3:
        // cambios en la presnetacion de los viajes cumplido

        // version 2.1.0:
        // incluye la seccion de pre-liquidacion
        // cambios de estilo

    }
}
