import { Injectable } from '@angular/core';
import { NotifyModel } from '../models/notify.model';
import { Subject } from 'rxjs';

@Injectable()
export class NotifyService {

    public notice: Subject<NotifyModel> = new Subject<NotifyModel>();

    notify(message: string, title: string, delay?: number) {
        this.notice.next({ message, title, delay });
    }
}
