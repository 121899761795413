import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { locale, loadMessages } from 'devextreme/localization';
import 'devextreme-intl';


@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private router: Router) {
    const messagesEs = require('../assets/messages/es.json');
    loadMessages(messagesEs);
    locale('es');
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
