import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';
import { LoginComponent } from './pages/login/login.component';


import { CanActivateUser } from './auth/can-activate';



export const routes: Routes = [
  { path: '', redirectTo: '/home/start', pathMatch: 'full' },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  }, {
    path: 'login',
    // component: LoginComponent,
    data: {
      title: 'Página de Ingreso'
    },
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  }, {
    path: 'resetl',
    // component: LoginComponent,
    data: {
      title: 'Recuperar Contraseña'
    },
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
  }, {
    path: 'home',
    component: DefaultLayoutComponent,
    // loadChildren: () => import('./containers/default-layout/default-layout.module').then(m => m.DefaultLayoutModule),
    canActivate: [CanActivateUser],
    data: {
      title: 'TC'
    },
    children: [
      {
        path: 'start',
        // loadChildren: './pages/startpage/startpage.module#StartpageModule',
        loadChildren: () => import('./pages/requestpage2/requestpage2.module').then(m => m.Requestpage2Module)
      }, {
        path: 'request',
        // loadChildren: './pages/requestpage/requestpage.module#RequestpageModule'
        loadChildren: () => import('./pages/requestpage/requestpage.module').then(m => m.RequestpageModule)
      }, {
        path: 'request2',
        // loadChildren: './pages/requestpage2/requestpage2.module#Requestpage2Module'
        loadChildren: () => import('./pages/requestpage2/requestpage2.module').then(m => m.Requestpage2Module)
      }, {
        path: 'agree',
        // loadChildren: './pages/agreementpage/agreementpage.module#AgreementpageModule'
        loadChildren: () => import('./pages/agreementpage/agreementpage.module').then(m => m.AgreementpageModule)
      }, {
        path: 'agree2',
        // loadChildren: './pages/agreementpage/agreementpage.module#AgreementpageModule'
        loadChildren: () => import('./pages/agreementpage2/agreementpage2.module').then(m => m.Agreementpage2Module)
      }, {
        path: 'dashboard',
        // loadChildren: './pages/dashboardpage/dashboardpage.module#DashboardpageModule'
        loadChildren: () => import('./pages/dashboardpage/dashboardpage.module').then(m => m.DashboardpageModule)
      }, {
        path: 'curso',
        loadChildren: () => import('./pages/loadscurrent/loadscurrent.module').then(m => m.LoadsCurrentModule)
      },  {
        path: 'cumplido',
        // loadChildren: () => import('./pages/viajescumplidos/viajescumplidos.module').then(m => m.ViajescumplidosModule)
        loadChildren: () => import('./pages/loadsfinish/loadsfinish.module').then(m => m.LoadsFinishModule)
      },  {
        path: 'liquidacion',
        // loadChildren: './pages/agreementpage/agreementpage.module#AgreementpageModule'
        loadChildren: () => import('./pages/liquidationdocs/liquidationdocs.module').then(m => m.LiquidationdocsModule)
      },  {
        path: 'vehicles',
        loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule)
      },  {
        path: 'trailers',
        loadChildren: () => import('./pages/trailers/trailers.module').then(m => m.TrailersModule)
      },  {
        path: 'drivers',
        loadChildren: () => import('./pages/drivers/drivers.module').then(m => m.DriversModule)
      }
    ]
  }
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },
  // {
  //   path: '',
  //   component: DefaultLayoutComponent,
  //   data: {
  //     title: 'Home'
  //   },
  //   children: [
  //     {
  //       path: 'request',
  //       component: RequestpageComponent,
  //       canActivate: [CanActivateUser]
  //     }, {
  //       path: 'request2',
  //       component: Requespage2Component,
  //       canActivate: [CanActivateUser]
  //     }, {
  //       path: 'agreement',
  //       component: AgreementpageComponent,
  //       canActivate: [CanActivateUser]
  //     }, {
  //       path: 'dashboard',
  //       component: DashboardpageComponent,
  //       canActivate: [CanActivateUser]
  //     }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
