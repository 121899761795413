import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxPopupModule, DxButtonModule, DxTextAreaModule } from 'devextreme-angular';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';
import { CaptchaModule } from 'primeng/captcha';


@NgModule({
  declarations: [],
  exports: [
    CaptchaModule,
    StepsModule,
    PanelModule,
    SplitButtonModule,
    ButtonModule,
    ToolbarModule,
    TabViewModule,
    ConfirmDialogModule,
    ToastModule,
    FormsModule
  ], providers: [
    ConfirmationService,
    MessageService
  ],
  imports: [
    CaptchaModule,
    StepsModule,
    PanelModule,
    SplitButtonModule,
    CommonModule,
    ButtonModule,
    ToolbarModule,
    TabViewModule,
    ConfirmDialogModule,
    ToastModule,
    FormsModule
  ]
})
export class CommondialogModule { }
