import { Component, Input, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { Authorization } from '../../auth/authorization';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Message, MessageService, ConfirmationService } from 'primeng/api';
import { AuthFirestoreService } from '../../services/authFirestore.service';
import { RequestFirestoreService } from 'app/services/requestFirestore.service';
import { NotifyTotalsService } from 'app/services/notifytotals.service';
import { VersionAppModel } from 'app/models/versionapp.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  apiserver: string;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  notify = 0;
  private _hubConnection: HubConnection;
  msgs: Message[] = [];
  totalRequests = 0;
  totalAgree = 0;
  versionapp: VersionAppModel;
  showMenu = false;
  // tslint:disable-next-line:no-unused-expression


  constructor(
    public auth: AuthFirestoreService,
    private rtr: Router,
    public reqservice: RequestFirestoreService,
    private nst: NotifyTotalsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    // tslint:disable-next-line: deprecation
    nst.notice.subscribe(_notice => {
      // console.log('Notice xxxxxxx->', _notice);
      this.totalRequests = _notice.totalRequest;
      this.totalAgree = _notice.totalAgree;
    });
    this.versionapp = new VersionAppModel();
    this.showMenu = this.getMenu();
  }

  navegar(ruta) {
    this.rtr.navigate([ruta], { skipLocationChange: true });
  }

  logOff() {
    this.confirmationService.confirm({
      message: 'Esta seguro de salir de la Aplicación ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        sessionStorage.clear();
        this.auth.logOutUser();
        this.rtr.navigate(['/login']);
      },
      reject: () => {
        // this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
    });

  }

  ngOnInit(): void {


    // this.apiserver = this.config.getConfig('bopisBaseUrl') + '/SignalR/notify';
    // console.log(this.apiserver);
    // this._hubConnection = new HubConnectionBuilder().withUrl(this.apiserver).build();

    // this._hubConnection
    //   .start()
    //   .then(() => console.log('Connection started1!'))
    //   .catch(err => console.log('Error while establishing connection 1, ' + err));

    // this._hubConnection.on('BroadcastMessage', (type: string, payload: string) => {
    //   this.msgs.push({ severity: type, summary: payload });
    //   this.notify = this.msgs.length;
    // });
  }

  changeMenu(totalsRequest: number, totalsAgree: number) {
    this.navItems = [{
      name: 'Dashboard Operación',
      url: '/home/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Bolsa Disponible2 ',
      url: '/home/request2',
      icon: 'icon-bell',
      badge: {
        variant: 'info',
        text: ' ( ' + totalsRequest + ' ) '
      }
    },
    {
      name: 'Mis Viajes ',
      url: '/home/agree',
      icon: 'icon-pin',
      badge: {
        variant: 'secondary',
        text: ' ( ' + totalsAgree + ' ) '
      }
    },
    {
      name: 'Estado de Cuenta',
      url: '/home/xx',
      icon: 'icon-layers'
    }];
  }

  toggleMenu() {
    const x = document.getElementById('toggleMenus');
    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  getMenu(): boolean {
    const compid = this.auth.currentUser.usrComid;
    switch (compid) {
      case '-Kutcf1qBWwZ7Qs84q-w':
        return true;
      case 'SPX_ECO_COM_QA':
        return true;
      case 'SPX_ECO_COM_PROD':
        return true;
      default:
        return true;
    }
  }


//   danielromerogil@hotmail.com
// 9004766465

}
