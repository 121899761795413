export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCDqT0wOXwyMlmaxPDgUo-3IDoK3GWv8t4',
  authDomain: 'spxgo-ccd34.firebaseapp.com',
  databaseURL: 'https://spxgo-ccd34.firebaseio.com',
  projectId: 'spxgo-ccd34',
  storageBucket: 'spxgo-ccd34.appspot.com',
  messagingSenderId: '315425906491'
};

export const FIREBASE_CONFIG1 = {
  apiKey: 'AIzaSyA2cg7lYXpQKVHaUEjl9vloXNTGb6IsFZU',
  authDomain: 'apptest1-ab5d6.firebaseapp.com',
  databaseURL: 'https://apptest1-ab5d6.firebaseio.com',
  projectId: 'apptest1-ab5d6',
  storageBucket: 'apptest1-ab5d6.appspot.com',
  messagingSenderId: '18774910675'
};
