import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'app/models/interfaces/responseModel';
import { BaseService } from 'app/models/interfaces/baseService';
import { AuthExternalService } from './authExternal.service';
import { environment } from '../../environments/environment.prod';
import * as firebase from 'firebase';
import { promises } from 'fs';
import { AuthFirestoreService } from './authFirestore.service';


@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends BaseService {

  apiUrl = '';
  trafficapiUrl = environment.trafficApiUrl;
  documentsPath = 'Vehiculos/Documentos';

  constructor(private http: HttpClient, private autService: AuthExternalService, public auth: AuthFirestoreService) {
    super(http, autService, auth);
    this.apiUrl = this.getUrl();
  }

  getRequiredDocumentsByEntity(entity: string): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}documents/GetRequiredDocumentsByEntity/${entity}`);
  }

  getAssignedDocuments(entity: string, code: string): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}documents/GetAssignedDocuments/${entity}/${code}`);
  }

  getReasonsByDocId(id: string): Promise<ResponseModel> {
    return this.getData(`${this.apiUrl}documents/GetReasonsByDocId/${id}`);
  }

  addDocument(model: any): Promise<ResponseModel> {
    return this.post(`${this.apiUrl}/documents/AddDocument`, model);
  }

  editDocument(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/documents/UpdateDocument`, model);
  }

  deleteDocument(model: any): Promise<ResponseModel> {
    return this.patch(`${this.apiUrl}/documents/DeleteDocument`, model);
  }

  uploadFileToFirebase(fileData: any, code: string, entity: string): Promise<any> {
    const uploadRef = this.documentsPath;
    const carrierId = this.auth.currentUser.usrCarrierId;
    return new Promise(function (resolve, reject) {
      const file: File = fileData[0];
      const storageRef = firebase.storage().ref();

      const uploadTask = storageRef.child(`${uploadRef}/${carrierId}/${entity}/${code}/${file.name}`).put(file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Subiendo archivo - ' + progress + '% completado');
        }, function (error: any) {
          console.error('ERROR SUBIENDO ARCHIVO A FIREBASE: ', error);
          reject();
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            resolve(downloadURL);
          });
        });
    });
  }

}

